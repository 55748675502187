export const LOGIN = "auth/login";
export const FORGOT_PASSWORD_EMAIL = "auth/forgot-password";
export const UPDATE_PASSWORD_WITH_OTP = "auth/update-password-with-otp";
export const RESEND_OTP_EMAIL = "auth/resent-otp-email";
export const SETTING = "setting";
export const UPDATE_PROFILE = "users/admin/profile-update";
export const UPDATE_PASSWORD = "users/change-password";
export const LOAD_EMAIL = "email";
export const LOAD_PAGE = "cms";
export const LOAD_FAQ = "faq";
export const LOAD_SUBSCRIPTION = "subscription";
export const LOAD_CATEGORY = "category";
export const LOAD_SUB_CATEGORY = "sub-category";
export const LOAD_QUIZ_TOPIC = "quizTopic";
export const LOAD_QUIZ = "quiz";
export const LOAD_VIDEO = "video";
export const LOAD_STUDY = "study";
export const LOAD_NEWS = "news-feed";
export const LOAD_CUSTOMER = "customer";
export const LOAD_ALL_CUSTOMER = "customer/get-all";
export const LOAD_CUSTOMER_PASSWORD = "customer/change-password";
export const DASHBOARD = "users/dashboard";
export const GET_USER_LIST = "customer/list";
export const SEND_NOTIFICATION = "notification/sendNotification";
export const GET_NOTIFICATION = "notification";
export const LOAD_CONTACT_US = "cms/contactUs";
export const DELETE_NOTIFICATION = "notification/delete";
export const LOAD_ADMIN_PASSWORD = "admin/change-password";
export const LOAD_COURSE = "course";
export const LOAD_PROMO_CODE = "coupon";
export const LOAD_TICKET = "ticket";
export const ADD_REMARK = "customer/add-remark";
export const LOAD_PAYMENT_DATA = "coupon/payment";
export const LOAD_PAYMENT_DATA_CSV = "coupon/payment/download";
export const LOAD_ADMIN = "admin";
