import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function subCategoryDataReducer(
  state = initialState.subCategoryData,
  action
) {
  switch (action.type) {
    case types.LOADED_SUB_CATEGORY_DATA_SUCCESS:
      return action.subCategoryData;
    default:
      return state;
  }
}
